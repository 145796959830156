<template>
  <div class="row">
    <div class="col-12">
      <router-link to="/legal">Legal</router-link>
      <h1>Privacy Policy</h1>

      <h5>Effective February 23, 2018</h5>
      <p>
        Your privacy is very important to us. This Privacy Policy does not only
        concern registered users of the application Tjing (hereinafter, 'Tjing',
        'Our', 'We') but also to some extent those who uses Tjing as a guest
        user, and those solely visiting Our website (the website and the
        application are collectively referred to as the 'Services'). Please read
        below to see how Tjing as the controller in relation to processing the
        personal data that this Privacy Policy concerns, processes personal
        data. Note that this policy only concerns the processing of Personal
        Data.
      </p>

      <p>
        Questions or comments about this Privacy Policy, or if you want to
        update, delete, change, transfer any personal data we hold, or if you
        have a concern about the way in which we handle any privacy matter,
        please contact us by postal mail or email at:
      </p>

      <p>
        Tjing
        <br />Att. Privacy Officer <br />hello@tjing.se <br />Järntorgsgatan
        12-14 <br />413 01 Göteborg, Sweden
      </p>

      <h5>The personal data we collect and how we collect it</h5>

      <p>
        Tjing collects your personal data when you register an account, if you
        track, complete or upload activities using the Services, or if you
        choose to share it with Tjing as a guest user. There are several
        opportunities for you to share additional information about yourself and
        your activities with Tjing, including your name, nationality, email
        address, club, PDGA number, gender, age, activities, and profile photo.
        Information from tours, competitions and other events, such as
        competition results, may also be uploaded.
      </p>

      <p>
        We collect Personal Data when you use our application and/or our
        webpage. Information about what device you are using, network
        information, cookies, log files (including IP addresses, browser type
        and internet service provider), are automatically handed to us. This is
        done solely in order to improve our Services and the data gathered from
        this procedure are under no circumstances shared with anyone other than
        those supporting us in the improvement of our Services.
      </p>

      <h5>How we use your personal data</h5>
      <p>
        Tjing primarily uses the personal data we collect to administer and
        operate the Services. As mentioned above, we also use your personal data
        to analyze, develop and improve the Services. We may also use your
        information to market our Services, provide support related to the
        Services, protect members and enforce our Terms of Service, promote
        safety, and communicate with you. Additionally, your information may be
        shared with a third party, as set forth below.
      </p>

      <p>
        We may use personal data to promote our service. For instance, we may
        publish a leaderboard or a result of a particular competition. Only
        names may appear for this purpose.
      </p>

      <p>
        We will also use your personal data in order to provide you with
        information about competitions (i.a. changed tee times, canceled and
        upcoming competitions), leaderboards and news about Tjing and our
        Services. This is done by e-mail, and you are given the opportunity to
        end this procedure in every email received by us.
      </p>

      <h5>Collection from children</h5>

      <p>
        Tjing does not knowingly collect Personal Data from children under the
        age of 13. If you believe we may have accidentally received such
        information, please contact us immediately via email hello@tjing.se.
      </p>

      <p>Personal data collected from Third Parties</p>
      <p>
        The only time Tjing collects personal data from third parties is when we
        need to get your PDGA rating. This information is collected from an API
        provided by PGDA. However, in order to receive your PDGA rating, you
        must first provide us with your PDGA membership number. This is made
        optional when creating your account but can also be done afterwards when
        managing your profile.
      </p>

      <h5>How information is shared</h5>

      <p>
        Tjing does not under any circumstances share personal data to any other
        than those mentioned below.
      </p>

      <h5>Sharing within Tjing</h5>

      <p>
        We may share your information and content within Tjing to operate,
        improve, or promote the Services. Note that only those who need personal
        data to fulfill their duties are authorized access.
      </p>

      <h5>Service providers</h5>

      <p>
        As mentioned above regarding the maintenance of the Services, marketing
        and providing you with emails regarding news, competitions etc.,
        personal data may be shared with third parties who provide us with those
        services. These service providers will only have access to the
        information necessary to perform these limited functions on our behalf
        and are required by written agreements to protect and secure your
        information.
      </p>

      <h5>Legal requirements</h5>

      <p>
        We may share your personal data in order to fulfill a legal obligation,
        including if we determine that disclosure is necessary in order to
        prevent a violation of any legal rights.
      </p>

      <h5>Safeguarding your information</h5>

      <p>
        We take reasonable and appropriate measures to protect personal data
        from loss, misuse and unauthorized access, disclosure, alteration and
        destruction. The Services uses industry standard Secure Sockets Layer
        (SSL) technology to allow for the encryption of personal data. Tjing
        engages a company that is an industry leader in online security and
        Services verification to strengthen the security of Tjing’s Services.
      </p>

      <h5>How to opt-out or adjust notification and email preferences</h5>

      <p>
        We offer various ways to opt-out or opt-into our notifications. To
        choose to stop receiving certain emails from Tjing, please follow the
        unsubscribe instructions within each email communication or contact us
        at hello@tjing.se.
      </p>

      <h5>Your rights as a data subject</h5>
      <p>
        As a data subject, you have a statutory right to correct, amend, delete,
        limit or transfer personal data at any time.
      </p>
      <p>
        You also have the right to file complaints about the processing your
        personal data to the Data Protection Authority. You can reach them at
        www.datainspektionen.se
      </p>
      <p>
        For more information about, or execution of, your rights, please contact
        us at hello@tjing.se.
      </p>

      <h5>Retention of data</h5>

      <p>
        We will retain your information for as long as your account is active or
        as long as needed to provide you with our Services. We may also retain
        and use your information in order to comply with our legal obligations,
        resolve disputes, prevent abuse, and enforce our Agreements.
      </p>

      <h5>Legal basis for processing</h5>

      <p>
        Tjing’s legal basis for the processing of Personal Data are i)
        performance of the contract between us and you as a Tjing user, ii) if
        it is necessary for compliance with a legal obligation, iii) our
        legitimate interest, and in some cases iv) your consent. If legal basis
        for the processing is consent, you have a right to withdraw the consent
        at any time.
      </p>

      <h5>Changes</h5>

      <p>
        We may change this Privacy Policy at any time and from time to time. If
        the changes are significant, we will provide a prominent notice. The
        most recent version of the Privacy Policy is reflected by the version
        date located at the top of this Privacy Policy. If you object to any of
        the changes to Privacy Policy, you may cease using the Services and
        deactivate your account. Using the Services after a notice of changes
        has been communicated to you or published on the Services shall
        constitute your agreement to the changes.
      </p>

      <h5>Contact information</h5>
      <p>
        Questions or comments about this Privacy Policy, if you want to execute
        any of your rights as a data subject, or you have a concern about the
        way in which we have handled any privacy matter you can contact us by
        postal mail or email at:
      </p>

      <p>
        Tjing
        <br />Att. Privacy Officer <br />hello@tjing.se <br />Järntorgsgatan
        12-14 <br />413 01 Göteborg, Sweden
      </p>

      <p>
        Thanks for taking the time to learn about Tjing’s Privacy Policy, and
        thanks for trusting us to handle your personal data
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "PrivacyPolicy",
};
</script>
<style lang="scss" scoped>
p {
  color: $elephant;
  margin-bottom: 15px;
  @include Gilroy-Medium;
}
h1 {
  margin-top: 10px;
  display: block;
}
h5 {
  margin-bottom: 20px;
}
a {
  display: block;
  margin-top: 26px;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
  a {
    display: block;
    margin-top: 40px;
  }
}
</style>
